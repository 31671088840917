<script setup lang="ts">
import { useCarousel } from './useCarousel'
import type { WithClassAsProps } from './types'

const props = defineProps<WithClassAsProps>()

const { orientation, canScrollNext, scrollNext } = useCarousel()

const hidden = ref(true)

onMounted(() => {
  hidden.value = false
})
</script>

<template>
  <button
    :disabled="hidden || !canScrollNext"
    :class="cn('p-2', { 'rotate-90': orientation === 'vertical' }, props.class)"
    aria-label="Next slide"
    @click="scrollNext"
  >
    <slot>
      <span
        class="flex items-center justify-center rounded-full bg-primary p-2 group-disabled:bg-disabled"
      >
        <IconGalleryArrowRight
          class="text-primary-contrast group-disabled:text-disabled-contrast"
        />
      </span>
    </slot>
  </button>
</template>
